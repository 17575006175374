/**
 * Extra utility classes to help build UIs
 */
.bg-md-grey {
  background-color: $md-grey;
}

.md-border-radius {
  border-radius: 0.75rem;
}

.md-ellipsis {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}
