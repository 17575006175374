@use "@styles/cl/core/grid";

$border-color: #dddddd;

.modal {
  background-color: white;
  border: 1px solid $border-color;
  border-radius: grid.size(0.5);
  overflow: hidden;
  box-shadow:
    0px 3px 7px 0px rgba(black, 5%),
    0px 13px 13px 0px rgba(black, 4%),
    0px 29px 17px 0px rgba(black, 3%),
    0px 51px 20px 0px rgba(black, 1%);
  max-width: calc(100vw - grid.size(8));
  max-height: calc(100vh - grid.size(8));
  display: flex;
  flex-direction: column;
  .title {
    border-bottom: 1px solid $border-color;
    padding: grid.size(2);
  }
  .actions {
    border-top: 1px solid $border-color;
    display: flex;
    justify-content: flex-end;
    padding: grid.size(2);
    gap: grid.size(1);
  }
}
