@use "@styles/cl/core/grid";

:global(.srm-contact-cf-modal) {
  display: flex;
  transition: border 0.2s;
  position: relative;
  &:global(.srm-contact-cf-modal) {
    padding: 0;
  }
  :global(.s-alert-wrapper) {
    display: none;
  }
  .modal {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: none;
    margin-top: 10px;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #008aff;
    $max-height: 256px;
    $min-height: 128px;
    max-height: max(min(50vh, $max-height), $min-height);
  }
  &.modal-open {
    border: 1px solid #008aff;
    .modal {
      display: block;
    }
  }
}

.button {
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 10px;
  padding: 15px;
  appearance: none;
  border: none;
  background-color: transparent;
  border-radius: inherit;
  .title {
    font-size: grid.to-rem(13);
    font-weight: 600;
    color: #008aff;
    text-wrap: nowrap;
  }

  .count {
    background-color: #008aff;
    color: white;
    font-size: grid.to-rem(10);
    border-radius: 50%;
    height: grid.to-rem(18);
    width: grid.to-rem(18);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .caret {
    font-size: grid.to-rem(16);
    margin-left: auto;
    color: #008aff;
    transition: transform 0.2s;
    .modal-open & {
      transform: rotate(180deg);
    }
  }
}
