@use "@styles/cl/core/colors";

.grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 0;

  &.custom-space-5-4 {
    grid-template-columns: 50% 40%;
    gap: 10%;
  }

  &.equal-space {
    grid-template-columns: 1fr 1fr;
  }

  .grid-item {
    padding-bottom: 0.5rem;
    &.label {
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: $md-input-text-disabled;
    }
    &.no-padding {
      padding: 0;
    }
  }

  .slim-scroll {
    /* We need to give a max-height to the container first */
    max-height: 100px;
    overflow-y: auto;

    /* Slim scrollbar for Firefox */
    scrollbar-width: thin;
    scrollbar-color: #edf0f2 $white;
  }

  .slim-scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .slim-scroll::-webkit-scrollbar-track {
    background: $white;
  }

  .slim-scroll::-webkit-scrollbar-thumb {
    background: #edf0f2;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    &.wrap-on-mobile {
      grid-template-columns: 1fr; /* Stack items in a single column */
      gap: 0;
    }
  }
}
