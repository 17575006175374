@mixin color($color, $hover-color) {
  --bs-btn-color: white;
  color: $color;

  &:disabled {
    --bs-btn-disabled-color: #{$color};
  }

  &:hover {
    --bs-btn-hover-color: #{$hover-color};
  }

  &:active {
    --bs-btn-active-color: #{$hover-color};
  }

  &:focus-visible {
    --bs-btn-hover-color: #{$hover-color};
  }
}

.btn-md {
  font-size: 1rem;
  font-weight: 600;
  font-family: $font-family-sans-serif;
  border-radius: 0.56rem;
  padding: 0.656rem 0.875rem;
}

.btn-md-icon {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  box-sizing: content-box;
  padding: 0.64286rem !important;
}

.btn-primary {
  @include button-variant($btn-emphasis-color, $btn-emphasis-color);
  @include color(white, white);

  &-pill {
    @include button-variant($btn-emphasis-color, $btn-emphasis-color);
    @include color(white, white);

    border-width: 0.08rem;
    border-radius: 1rem;
    padding: 0.2rem 0.7rem;
  }
}

@mixin btn-md {
  font-weight: 700;
  border-radius: 0.21429rem;
  padding: 0.8rem 2.6rem;
}

.btn-md-primary {
  @include button-variant($md-market-dojo-blue, $md-market-dojo-blue);
  @include btn-md;
  @include color(white, white);
}

.btn-md-outline-primary {
  @include button-outline-variant($md-market-dojo-blue, $md-market-dojo-blue);
  @include btn-md;
  @include color($md-market-dojo-blue, white);
}

.btn-outlined-primary {
  @include button-outline-variant($btn-emphasis-color, $btn-emphasis-color);
  @include color($btn-emphasis-color, white);

  &-pill {
    @include button-outline-variant($btn-emphasis-color, $btn-emphasis-color);
    @include color($btn-emphasis-color, white);

    border-width: 0.08rem;
    border-radius: 1rem;
    padding: 0.2rem 0.7rem;
  }
}

.btn-sm {
  padding: 0.28571rem 0.71429rem;
}

//
// Null Button
//
// Removes all styling from buttons so they can be used as click targets for
// things like icons. This is preferred over added the click handler to the
// icon directly.
.btn-null {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}
