@use "../core/colors";

.timeline {
  position: relative;
  font-family: $font-family-sans-serif;

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  &-item {
    display: flex;
    position: relative;

    &:not(:last-child) {
      padding-bottom: 1rem;
    }

    &:before {
      background-color: colors.$md-primary;
      width: 1.5px;
      margin-left: 7px;
      bottom: 12px;
      content: "";
      position: absolute;
      top: 0;
      margin-top: 6px;
      height: 100%;
    }

    &:last-child:before {
      display: none;
    }
  }

  &-badge {
    line-height: 1.5;
    align-items: center;
    display: flex;
    align-items: baseline;
    position: relative;
    border: 0;

    > svg {
      fill: colors.$md-primary;
    }
  }

  &-body {
    flex: auto;
    max-width: 100%;
    min-width: 0;
    padding-left: 0.5rem;
  }

  &-highlight-first .timeline-item:first-child,
  &-highlight-last .timeline-item:last-child {
    .timeline-badge > svg {
      stroke: colors.$md-primary;
      stroke-width: 2;
    }
  }
}
