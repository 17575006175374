.loader-hide {
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 15px;
  top: 34px;
}

.host-permissions-modal select.js-select2 {
  display: inline-block;
  width: calc(100% - 20px);
  margin-right: 5px;
}

.host-permissions-modal .up-loader {
  display: inline-block !important;
}

.host-permissions-modal .hidden.up-loader {
  display: none !important;
}

.host-permissions-modal #hostPermissionsTabContent #eventPermission .modal-body {
  max-height: 600px;
  overflow-y: auto;
}

.host-permissions-modal .modal-footer .block-content {
  padding-top: 0px;
}

.permission-select-container {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 30px);
}

.host-permissions-modal .up-loader {
  width: 30px;
  text-align: center;
}

.event-list .modal-content-section .text-left .btn.btn-default {
  margin-top: 8px;
}

.non_licensed_user_rule_limit {
  color: red;
  font-style: italic;
  padding-top: 10px;
}

.loader_middle {
  padding-top: 35px;
}

.button_middle {
  padding-top: 25px;
}

.vertical-scrollbar {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.icon-badge {
  padding: 3px;
  border: 1px solid white;
  border-radius: 50%;
  translate: -3px;
}

@media (max-width: 767px) {
  .host-permissions-modal {
    width: 95% !important;
  }
  .event-list .modal-content-section .text-left .btn.btn-default {
    margin: 0;
  }
}
