/**
 * The bootstrap variable overrides for Market Dojo. For all of the available
 * variables please see the bootstrap variables file.
 *
 * ./node_modules/bootstrap5/scss/_variables.scss
 */

$form-check-input-width: 1.25rem;
$input-btn-focus-color: $md-secondary;
$form-check-input-color: $md-primary;
$form-check-input-bg: white;
$form-check-input-focus-border: $md-primary;
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-color: $md-primary;
$form-check-input-checked-bg-color: white;
$form-check-input-checked-border-color: $md-primary;

// Tabs
$nav-link-padding-x: 1.5rem;
$nav-pills-border-radius: 0;

// Modal
$modal-padding: 1rem;
$modal-header-padding: 1rem 1rem;
$modal-border-radius: 0.5rem;
$modal-border-width: 1px;
$modal-footer-border-width: 1px;
$modal-header-padding-x: 1rem;
$modal-header-padding-y: 1rem;
$modal-footer-gap: 0.5rem;

// Price Input
$price-input-border-radius: 0.3rem;
