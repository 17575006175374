@mixin tag-brand($color, $background-color) {
  color: $color;
  border-color: $color;
  background-color: $background-color;
}

@mixin bullet() {
  content: "•";
  font-size: 2rem;
  line-height: 0;
  vertical-align: middle;
  margin-right: 4px;
  position: relative;
  top: -2px;
}

.custom-columns-tag {
  padding: 0.2rem 1rem;
  border-radius: 0.25rem;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  border-width: 1px;
  border-style: solid;

  position: relative;

  &.is-base {
    @include tag-brand(black, #edf0f2);

    border-color: #edf0f2;

    // This offsets the bullet for the base tag. When they are stacked on top
    // of each other all of the text should line up nicely.
    padding-left: 28px;
  }

  &.is-success {
    @include tag-brand(#138154, #e7f8f0);

    &:before {
      @include bullet();
    }
  }

  &.is-danger {
    @include tag-brand(#d88c45, #f8efe7);

    &:before {
      @include bullet();
    }
  }

  &.is-primary {
    @include tag-brand(#0d90ff, #f1f5ff);
  }

  &.is-pill {
    font-weight: bold;
    border-radius: 2.14286rem;
    padding: 0.42857rem 1.14286rem;
  }
}
