.modal-dialog {
  .modal-content {
    --bs-modal-bg: #{$modal-background-color};
    --bs-modal-border-radius: #{$modal-border-radius};
    --bs-modal-border-width: #{$modal-border-width};
    --bs-modal-border-color: #{$modal-border-color};
  }
  .modal-body,
  .modal-footer {
    --bs-modal-padding: #{$modal-padding};
    --bs-modal-footer-border-width: #{$modal-footer-border-width};
    --bs-modal-footer-border-color: #{$modal-footer-border-color};
    --bs-modal-footer-gap: #{$modal-footer-gap};
  }
  .modal-body {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 63vh;
  }
  .modal-header {
    --bs-modal-header-padding: #{$modal-header-padding};
    --bs-modal-inner-border-radius: #{$modal-border-radius};
    background-color: #{$modal-header-background-color};
    border-bottom: none;

    .btn-close {
      --bs-btn-close-opacity: 1;
      --bs-btn-close-bg: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBmaWxsPSIjY2NkMGQzIiBkPSJNMSAxbDE0IDE0bTAtMTRMMSAxNSIgc3Ryb2tlPSIjY2NkMGQzIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4=");
      --bs-modal-header-padding-x: #{$modal-header-padding-x};
      --bs-modal-header-padding-y: #{$modal-header-padding-y};
    }
  }
}

// Used to have the modal conveniently appear immediately in Storybook (without the need to trigger it each time)
// This is only for Storybook and does not affect the component when used elsewhere.
// See spec/components/stories/modal/modal_component_preview/default.html.erb
.modal-storybook {
  position: static;
  display: block;
}
