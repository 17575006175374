@use "sass:map";
@use "../core/colors";

$border-radius: 0.5rem;

.panel {
  border-radius: $border-radius;
  background-color: white;

  &-head {
    padding: map.get($spacers, 3);
    display: flex;
    align-items: center;
    &.has-border-bottom {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border-bottom: 1px solid colors.$panel-border-color;
    }
    &.has-bg-color {
      background-color: colors.$panel-head-background-color;
    }
  }

  &-spacer {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: map.get($spacers, 3);
    margin-right: map.get($spacers, 3);
    background-color: $md-border-grey;
    height: 0.071429rem;
    border: none;
  }

  &-title {
    font-size: 1.75rem;
    font-weight: bold;
    color: $md-title-color;
    flex: 1;
    &.medium-text {
      font-size: 1.25rem;
    }
  }

  &-content {
    padding: map.get($spacers, 3);
  }

  &.has-border {
    border-color: colors.$panel-border-color;
  }
}
