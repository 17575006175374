a.link {
  font-weight: bold;
  background-color: transparent;
  text-decoration: none;

  &.link-plain {
    color: $link-plain-color;
  }

  &.link-emphasis {
    color: $link-emphasis-color;
  }

  &.link-button {
    --bs-btn-color: #{$link-emphasis-color};
  }
}

a.link,
a.btn {
  &.link-small {
    font-size: 0.8rem;
  }

  &.link-medium {
    font-size: 1rem;
  }

  &.link-large {
    font-size: 1.25rem;
  }
}
