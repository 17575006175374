.select2-results {
  ul.select2-results__options {
    margin: 0;
    padding: 0;
  }

  .select2-results__option {
    padding: 0 !important;
  }

  .form-check-input {
    margin-right: 0.5rem;
  }

  .form-check-label {
    padding: 0.5rem 1rem !important;
    margin: 0;
    width: 100%;
  }
}

.select2-container--default .select2-selection--multiple .select2-search__field {
  margin: 0 !important;
}

.select2-container--default .select2-selection--multiple {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-radius: 0.375rem;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  border-color: rgb(134, 182.5, 254);
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  margin: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  font-weight: bold;
  margin-right: 0.5rem;
  border-radius: 0.2rem;
  white-space: nowrap;
  line-height: 1.7 !important;

  color: white !important;
  background-color: $md-primary;
  border-color: $md-primary;
}

.select2-results__option,
.select2-results__option.select2-results__option--highlighted[aria-selected],
.select2-results__option.select2-results__option--highlighted {
  background-color: transparent !important;
}

.input-group.has-icon {
  .select2-selection__rendered {
    margin-left: 26px;
  }

  .z-selecticon {
    z-index: 10002;
  }
}
