.calendar-current-event {
  background-color: #008aff !important;
}

.calendar-close-event {
  background-color: #bbb6b6 !important;
}

.event-details-modal {
  padding: 0 !important;
}

.dashboard-view {
  min-height: 70vh;
  width: 100%;
}

.rbc-toolbar {
  font-size: 14px !important;
}

.rbc-calendar {
  height: 75vh;
}
.close-icon {
  position: absolute;
  right: 15px;
  top: 20px;
  color: #999999;
  z-index: 1;
  cursor: pointer;
}

.close-icon:hover {
  color: #999999;
}

.modal-btn-close {
  background: #008aff;
  color: #fff;
}

.modal-btn-close:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.form-check-input,
.form-check-label {
  cursor: pointer;
}

.form-check-inline input.form-check-input {
  margin: 0 10px 0 0;
  vertical-align: middle;
}

.filter-box .panel-body {
  max-height: 200px;
  overflow-y: auto;
}

.rbc-btn-group button {
  color: #0d90ff;
  border-radius: 2px;
  font-size: 13px;
  border-color: #0d90ff;
  background: #fff;
}

.rbc-btn-group button.rbc-active,
.rbc-btn-group button:focus,
.rbc-btn-group button:hover {
  background: #0d90ff;
  box-shadow: none;
  color: #fff;
  outline: none;
  border-color: #0d90ff;
}

.full-view .rbc-btn-group button {
  min-width: 80px;
}

.event-details-modal tbody td:first-child {
  font-weight: 600;
}

.calendar-current-event {
  color: #fff !important;
}

.calendar-close-event {
  color: #fff !important;
}

.full-view .event-loader {
  position: absolute;
  left: -2px;
  padding-top: 2px;
}

.dashboard-view .event-loader {
  position: absolute;
  left: 5px;
  padding-top: 10px;
}

.dashboard-view .rbc-toolbar-label {
  width: 0;
}

.event-details-modal tr td {
  padding: 12px 15px !important;
}

.full-view .rbc-row-content {
  max-height: 250px;
  overflow: auto;
}

.dashboard-view .rbc-row-content {
  max-height: 200px;
  overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  border-left: 1px solid #ddd;
}

.rbc-label.rbc-time-header-gutter {
  overflow-wrap: break-word !important;
  text-align: center !important;
  margin-top: auto !important;
}

.table-bordered tr td:first-child {
  max-width: 160px !important;
}

.calendar-event-table {
  max-height: 300px;
  overflow-y: auto;
}

.calendar-event-table .table {
  margin: 0;
}

.full-view .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
  width: 200px;
}

.full-view input[type="checkbox"]:not(old) {
  width: 2em;
  margin: 0;
  padding: 0;
  font-size: 1em;
  opacity: 0;
  cursor: pointer;
}

.full-view input[type="checkbox"]:not(old) + label {
  display: inline-block;
  margin-left: -2em;
  line-height: 1.5em;
  cursor: pointer;
}

.full-view input[type="checkbox"]:not(old) + label > span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0.25em 0.5em 0.25em 0.25em;
  border: 1px solid #e2e2e2;
  border-radius: 0;
  background: transparent;
  vertical-align: middle;
}

.full-view input[type="checkbox"]:not(old):checked + label > span:before {
  content: "\f00c";
  display: block;
  width: 19px;
  color: #8e8e8e;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  text-shadow: none;
  font-weight: bold;
  font-family: FontAwesome;
}

button[disabled] {
  cursor: not-allowed;
}

.rbc-overlay {
  max-height: 500px;
  overflow-y: auto;
}

.rbc-calendar .rbc-month-row {
  flex-basis: inherit;
}

.rbc-calendar .rbc-date-cell {
  width: 100%;
}

@media (max-width: 1199px) {
  .dashboard-view .rbc-toolbar {
    display: block;
  }

  .dashboard-view .event-loader {
    padding-top: 5px;
  }

  .dashboard-view .rbc-btn-group {
    display: block;
    white-space: normal;
  }

  .dashboard-view .rbc-toolbar-label {
    display: block;
    text-align: left;
    padding: 10px 0;
    width: 100%;
  }

  .full-view .event-loader {
    padding-top: 11px;
  }

  .full-view .rbc-toolbar {
    display: block;
  }

  .full-view .rbc-toolbar-label {
    display: block;
    text-align: left;
    padding: 10px 0;
  }

  .rbc-calendar .rbc-toolbar .rbc-toolbar-label {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .filter-box,
  .full-view {
    width: 100%;
  }

  .full-view .event-loader {
    padding-top: 5px;
    left: 0;
  }
}
@media (max-width: 768px) {
  .filter-box,
  .full-view {
    width: 100%;
  }

  .full-view .rbc-btn-group {
    display: block;
    white-space: normal;
  }

  .full-view .event-loader {
    left: -15px;
  }
}
@media (max-width: 414px) {
  .event-details-modal {
    width: 90% !important;
  }
}
