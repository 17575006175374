@use "sass:math";
@use "@styles/cl/core/grid";
@use "@styles/cl/core/colors";
$background: #f2f4f6;
$dot: #dddfe2;
$surface: #ffffff;
$brand: colors.$md-primary;

.container {
  background-color: $background;
  background-image: radial-gradient(circle at grid.size(2) grid.size(2), $dot 2px, transparent 0);
  background-size: grid.size(4) grid.size(4);
  padding: grid.size(6);
  width: 100%;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.node {
  background: $surface;
  box-shadow:
    0px 23px 50px 0px rgba(black, 3%),
    0px 91px 91px 0px rgba(black, 3%),
    0px 205px 123px 0px rgba(black, 2%);
  padding: grid.size(3);
  display: flex;
  align-items: center;
  gap: grid.size(2);
  border-radius: grid.size(0.5);
  min-height: grid.size(8);
  &.collapsed {
    padding: grid.size(1.5);
    min-height: grid.size(4);
    gap: grid.size(1.5);
    .parameters,
    .actions {
      gap: grid.size(1.5);
    }
  }
  .parameters {
    display: flex;
    align-items: center;
    gap: grid.size(2);
    flex: 1;
  }
  .actions {
    display: flex;
    align-items: center;
    gap: grid.size(2);
    .add-condition {
      height: grid.size(4);
    }
  }
}

.collapsed-value {
  display: flex;
  align-items: center;
  padding: grid.size(0.5) grid.size(1);
  border-radius: grid.size(0.5);
  background-color: rgba($brand, 0.1);
  color: $brand;
  font-weight: bold;
}

.branch {
  .branch-contents {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  display: flex;
  padding: grid.size(0.5);
  gap: grid.size(1);
  border-radius: grid.size(0.5);
  border: 2px dashed $brand;
  margin: -1px 0;
  &.no-border {
    padding: 0;
    border: none;
    margin: 0;
  }
  &.collapsed {
    .dropdown {
      height: grid.size(5.5);
      .connector {
        height: grid.size(0.75);
      }
    }
  }
  .dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: grid.size(8);
    .select-container {
      position: relative;
      background-color: $brand;
      color: white;
      height: grid.size(4);
      min-width: grid.size(9);
      border-radius: grid.size(2);
      margin: 0;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      &.focused {
        border: 1px solid $brand;
        box-shadow: 0 0 0 0.25rem rgba($brand, 0.5);
      }
    }
    .select-icon {
      position: absolute;
      width: grid.size(2);
      height: grid.size(1.5);
      right: grid.size(1);
      top: calc(50% - grid.size(0.75));
      pointer-events: none;
      path {
        stroke: currentColor;
      }
    }
    .select {
      width: 100%;
      height: 100%;
      background-color: transparent;
      appearance: none;
      border: none;
      outline: none;
      font-weight: 700;
      text-transform: uppercase;
      padding-left: grid.size(1.5);
      padding-right: grid.size(3.5);
      option {
        color: initial;
      }
    }
    .connector {
      height: grid.size(2);
      border: 1px dashed $brand;
      width: 0;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        display: block;
        $connector-size: grid.size(0.75);
        left: math.div($connector-size, -2);
        height: $connector-size;
        width: $connector-size;
        background-color: $brand;
        border-radius: 50%;
      }
      &.before {
        &::before {
          top: grid.size(-0.5);
        }
      }
      &.after {
        &::before {
          bottom: grid.size(-0.5);
        }
      }
    }
  }
  .collapse-container {
    $button-size: 2;
    margin-right: calc(
      #{grid.size(((math.div($button-size, 2)) + 0.5) * -1)} - 1px
    ); // half button size + parent's padding + half border width
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:focus .collapse-button {
      color: #333;
    }

    &:hover,
    &:focus-visible {
      .collapse-button {
        background-color: $brand;
        color: white;
      }
    }

    &:focus-visible .collapse-button {
      box-shadow: var(--bs-btn-focus-box-shadow);
    }

    .collapse-button {
      border-width: 2px;
      background-color: $background;
      box-sizing: border-box;
      border-radius: 50%;
      height: grid.size($button-size);
      width: grid.size($button-size);
      padding: 0 !important; // the icon button styles use !important so we have to too, weee
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: grid.size($button-size * 0.5);
    }
  }
}

:global(.md-bs5 .btn).floating-button.floating-button {
  align-self: center;
  border-radius: 16rem; // unreasonably large to make pill shape
}

.node,
.branch {
  & + .floating-button {
    margin-top: grid.size(2);
  }
  :global(.md-bs5) & {
    :global(.form-control),
    :global(.form-select) {
      width: unset;
    }
  }
}

.input-container {
  display: flex;
  align-items: center;
  :global(.md-bs5) & {
    :global(.invalid-feedback),
    :global(.form-text) {
      margin: 0;
      margin-left: grid.size(2);
      text-wrap: nowrap;
    }
  }
}

.word {
  font-weight: 700;
  //color: $brand;
  font-size: grid.to-rem(12);
  text-transform: uppercase;
}
