@import "@styles/cl/core/colors";

:root {
  --navbar-background-color: #{$md-secondary};
  --navbar-text-color: white;
  --navbar-standout-color: #{$md-primary};
  --navbar-standout-color-hover: #03b0c3;
  --navbar-font-family: "Source Sans Pro", "Open Sans", sans-serif;
  --navbar-font-size: 16px;
}

/* DESKTOP */
@media screen and (min-width: 1212px) {
  .esker-navbar-mobile {
    display: none !important;
  }
}

nav:not(.esker-navbar):not(.esker-navbar-mobile) .navbar-brand {
  height: auto;
  width: 130px;
  line-height: inherit;

  img {
    width: inherit;
    object-fit: scale-down;
    max-height: 60px;
  }

  & > svg {
    vertical-align: middle;
  }
}

.esker-navbar {
  background-color: var(--navbar-background-color);
  flex-wrap: nowrap;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 55;
  min-width: 320px;
  backface-visibility: hidden;
  height: 60px;
  box-shadow: 0px 4px 6px RGB(0 0 0 / 10%);
}

.esker-navbar-row {
  display: flex;
  flex-wrap: wrap;
}

.esker-navbar-item {
  border-radius: 9px;
}

.esker-navbar-item:hover:not(.empty-block) {
  background-color: #f7f9f9;
  text-decoration: underline;
}

.esker-navbar-item.empty-block {
  cursor: default;
}

$padding-values: (5, 10);

@each $value in $padding-values {
  .esker-navbar-ptb#{$value} {
    padding-top: #{$value}px;
    padding-bottom: #{$value}px;
  }
}

.esker-navbar-plr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

$padding-values: (5, 6, 7, 10);

@each $value in $padding-values {
  .esker-navbar-p#{$value} {
    padding: #{$value}px !important;
  }
}

.esker-navbar-pb10 {
  padding-bottom: 10px;
}

.esker-navbar-dropdown-svg-container {
  border: 1px solid #dee2e6;
  background-color: white;
  text-align: center;
  padding: 5px 5px;
  border-radius: 9px;
  margin-right: 10px;
  width: 35px;
}

.esker-navbar svg {
  vertical-align: middle;
}

.esker-navbar-link {
  color: var(--navbar-text-color);
  font-size: var(--navbar-font-size);
  font-family: var(--navbar-font-family);
  text-decoration: none;
  padding: 0 14px;

  &:hover {
    color: var(--navbar-text-color);
    text-decoration: underline;
  }

  &:focus,
  &:active {
    color: var(--navbar-text-color);
  }
}

.esker-navbar-link-dropdown {
  color: #035e68 !important;
  font-family: var(--navbar-font-family);
  font-size: 15px;
  font-weight: 600 !important;
  text-decoration: none;
}

.esker-navbar-link-dropdown:hover {
  color: #272833;
}

a.esker-navbar-link-dropdown:hover {
  background-color: #f7f9f9 !important;
  background-image: none !important;
}

.esker-navbar-link-dropdown-icon {
  padding: 3px;
  border-radius: 5px;
  margin-right: 8px;
}

.esker-navbar-link-dropdown:hover .esker-navbar-link-dropdown-icon {
  background-color: #035e68;
}

.esker-navbar-link-dropdown:hover .esker-navbar-circle-icon {
  border: 1px #035e68 solid;
  background-color: #035e68;
  color: white;
}

.esker-navbar-link-dropdown svg {
  fill: #035e68;
}

.esker-navbar-link-dropdown:hover svg {
  fill: white;
}

.esker-navbar-link[data-method="delete"]:hover {
  color: #f95151;
}

.esker-navbar-text-dark {
  font-family: var(--navbar-font-family);
  color: #272833 !important;
}

.esker-navbar-text-shadowblue {
  font-family: var(--navbar-font-family);
  color: #798fa4;
}

.esker-border {
  border: 1px solid #e3e1e1 !important;
  border-radius: 9px !important;
  box-shadow: 0 1rem 3rem RGB(0 0 0 / 18%) !important;
}

.esker-primarynav-resources {
  background-color: #f9f9f9;
  border-radius: 0 0 9px 9px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}

.esker-navbar .navbar-brand {
  height: 42px;
  width: 128px;
  line-height: inherit;

  img {
    width: inherit;
    object-fit: scale-down;
    max-height: 60px;
  }
}

.esker-navbar .dropdown-menu {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 2px;
  top: 111% !important;
  transform: none !important;
}

.esker-navbar .dropdown-menu .arrow::after {
  position: absolute !important;
  display: block;
  border-color: transparent;
  border-style: solid;
  top: -19px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
  border-width: 10px;
}

#esker-navigation-primary-menu {
  width: 450px;

  &:not(:has(div#esker-navigation-primary-menu-links)) {
    width: auto;
  }
}

#esker-navigation-primary-menu .arrow::after {
  left: 48px;
}

#esker-navigation-admin {
  left: -302px !important;
  width: max-content;
}

#esker-navigation-admin .arrow::after {
  left: 345px !important;
}

#esker-navigation-admin:has(.esker-navbar-panel-50.d-none) {
  left: -102px !important;
  width: min-content;
}

#esker-navigation-admin:has(.esker-navbar-panel-50.d-none) .arrow::after {
  left: 145px !important;
}

#esker-navigation-user {
  width: 170px;
}

#esker-navigation-user .arrow::after {
  left: 75px;
}

#esker-navigation-locale {
  left: -81px !important;
  width: 210px;
}

#esker-navigation-locale .arrow::after {
  left: 94px;
}

#esker-navigation-sandpit {
  left: -28px;
  width: 180px;
}

#esker-navigation-sandpit .arrow::after {
  left: 78px;
}

.esker-navigation-scrolling {
  overflow-y: auto;
  height: 357px;
}

.esker-navbar .btn-headbar {
  cursor: pointer;
}

.esker-navbar .btn-headbar .caret {
  transition: transform 0.1s;
}

.esker-navbar .btn-headbar-full {
  background-color: var(--navbar-standout-color) !important;
  border-color: var(--navbar-standout-color) !important;
}

.esker-navbar-dropdown-header {
  display: block;
  font-family: var(--navbar-font-family);
  font-size: var(--navbar-font-size);
  font-weight: 600;
  padding: 0.5rem 1rem;
  padding-left: 0px;
  margin-bottom: 0;
  color: #798fa4;
  line-height: 1.5;
  white-space: nowrap;
}

.esker-navbar-dropdown-button {
  color: white;
  cursor: pointer;
  background-color: var(--navbar-standout-color);
  border: none;
  border-radius: 10px;
  padding: 10px 14px;
  font-size: 14px;
  font-family: var(--navbar-font-family);
}

.esker-navbar-dropdown-button:hover {
  background-color: var(--navbar-standout-color-hover);
}

.esker-navbar-dropdown-ul {
  padding-inline-start: 18px;
  margin-left: 0;
}

.esker-navbar-dropdown-ul li {
  list-style: none;
  position: relative;
}

.esker-navbar-dropdown-ul {
  li::before {
    content: "•";
    position: absolute;
    left: -7px;
  }
  li.list-bullet-plus::before {
    content: "+";
    left: -8px;
  }
}

.esker-navbar-panel-50 {
  flex: 0 0 auto;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 50% !important;
  min-width: 200px;
}

.esker-navbar-dropdown-right {
  right: 0;
  left: auto;
}

.esker-center-content-vertical {
  display: flex;
  align-items: center;
}

.esker-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.esker-navbar-circle {
  border-radius: 50%;
  border: 1px var(--navbar-text-color) solid;
  width: 33px;
  height: 33px;
  line-height: 19px !important;
  color: var(--navbar-text-color);
  text-align: center;
}

.esker-navbar-circle-icon {
  border-radius: 50%;
  border: 1px #035e68 solid;
  width: 28px;
  height: 28px;
  color: #035e68;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
}

.btn-headbar {
  background-color: var(--navbar-background-color) !important;
  border: 1px solid var(--navbar-text-color) !important;
  border-radius: 10px !important;
  color: var(--navbar-text-color) !important;
  font-size: var(--navbar-font-size) !important;
  font-family: var(--navbar-font-family);
  padding: 3px 12px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn-headbar:hover {
  text-decoration: underline;
}

.btn-headbar:focus {
  outline: 0;
}

.navbar-border-0 {
  border: 0 !important;
}

.nav-link.text-navbar:hover {
  color: whitesmoke !important;
}

.navbar-collapse {
  flex-grow: 1;
}

.navbar ul.navbar-nav {
  display: flex;
}

.display-block {
  display: block;
}

a[data-method="delete"] {
  color: #ed4d4d;
}

button[aria-expanded="true"] {
  text-decoration: underline;
}

button[aria-expanded="true"] .caret {
  transform: rotate(180deg);
}

.esker-navbar .nav-item {
  border-right: 1px solid #798fa4;
}

.esker-navbar .nav-item:last-child {
  border-right: none;
}

.esker-navbar .navbar-collapse.collapse {
  display: block !important;
  height: auto !important;
  padding-bottom: 0;
  overflow: visible !important;
  padding-left: 0;
  padding-right: 0;
}

.esker-navbar .navbar-nav {
  float: left;
  margin: 0;
  list-style: none;
}

.esker-navbar .navbar-nav > li {
  float: left;
}

.esker-navbar .center-content-vertical {
  display: flex;
  align-items: center;
}

$margin-values: (10, 15, 25);

@each $value in $margin-values {
  .esker-navbar .m-r#{$value} {
    margin-right: #{$value}px !important;
  }
}

$margin-values: (5, 10, 15, 25);

@each $value in $margin-values {
  .esker-navbar .m-l#{$value} {
    margin-left: #{$value}px !important;
  }
}

.esker-navbar * {
  box-sizing: border-box;
  line-height: 1.42857143;
}

.esker-navbar .center-content-horizontal-vertical {
  display: flex;
  justify-content: center;
  align-items: center;
}

.esker-navbar .m-t1 {
  margin-top: 1px;
}

.esker-navbar .m-t2 {
  margin-top: 2px;
}

.esker-navbar .m-t4 {
  margin-top: 4px !important;
}

.esker-navbar .m-t10 {
  margin-top: 10px;
}

.esker-navbar .p-0 {
  padding: 0px !important;
}

.esker-navbar .p-t0 {
  padding-top: 0px !important;
}

.esker-navbar .p-t2 {
  padding-top: 2px !important;
}

.esker-navbar .p-b0 {
  padding-bottom: 0px !important;
}

.esker-navbar .caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  // Ensure Bootstrap 2's caret does not interfere with our caret. Depending on
  // what module you are in its not guaranteed what bootstrap you are getting.
  // If Bootstrap 2 is loaded the caret margin will have a higher specificity
  // and win the css battle
  margin-top: 0 !important;
  margin-left: 2px !important;
}

.esker-navbar .btn-headbar {
  line-height: 24px;
}

.esker-navbar .border-right {
  border-right: 1px solid #eee;
}

.esker-navbar h6 {
  margin-top: 0;
}

.esker-navbar .bg-white {
  background-color: #fff !important;
}

.esker-navbar .font-s14 {
  font-size: 14px !important;
}

.esker-navbar .font-system-ui {
  font-family: system-ui;
  font-size: 14px;
}

.esker-navbar .col-lg-12 {
  width: 100%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.esker-navbar .col-lg-7 {
  width: 58.33333333%;
  float: left;
}

.esker-navbar .col-lg-5 {
  width: 41.66666667%;
  float: left;
}

.esker-navbar .text-right {
  text-align: right;
}

.esker-navbar .wid-100 {
  width: 100%;
}

#esker-navigation-locale.esker-navbar-left150 {
  left: -150px !important;

  .arrow::after {
    left: 162px !important;
  }

  &.dropdown-menu {
    border-radius: 9px !important;
  }
}

button#dropdownMenuLocale {
  background: transparent;
  border: none;
  padding: 0;
  text-align: inherit;
}

#dropdownMenuLocaleCaret {
  display: none;
}

.d-none {
  display: none;
}

// MOBILE + TABLETS
@media screen and (max-width: 1211px) {
  .esker-navbar {
    display: none !important;
    &-mobile {
      display: block !important;
      .navbar-toggle {
        display: block !important;
      }
    }
  }
  body.sim-dojo {
    padding: 0;
  }

  body#category_dojo {
    padding: 0;

    .esker-navbar-circle {
      width: 19px;
      height: 19px;
    }
    #esker-navigation-help,
    #esker-navigation-world {
      height: 34px;
    }
    #helpNavigation,
    #worldNavigation {
      width: 19px;
    }
    #dropdownMenuLocaleCaret {
      margin-top: 22px !important;
    }
    #esker-navigation-primary-menu a {
      line-height: 0;
    }
    .text-right {
      text-align: right;
    }
    #navbarNav.collapse {
      height: auto;
    }
    .wid-100 {
      width: 100%;
    }
  }
}

.esker-navbar-mobile {
  background-color: var(--navbar-background-color) !important;
  font-family: var(--navbar-font-family);

  .collapse.in {
    display: block !important;
  }

  #hamburger-menu {
    background-color: var(--navbar-background-color);
    outline: none;
  }

  #hamburger-menu-user {
    color: var(--navbar-text-color);
    width: 100% !important;
  }

  .btn-headbar {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .esker-navbar-link,
  .esker-navbar-link-dropdown,
  .esker-navbar-dropdown-header {
    color: var(--navbar-text-color) !important;
  }

  h6.esker-navbar-dropdown-header {
    filter: brightness(0.8);
  }

  .esker-navbar-item:hover,
  .esker-navbar-item a:hover {
    background-color: transparent !important;
    text-decoration: underline;
  }

  #esker-navigation-dropdown-admin {
    padding: 10px 20px 10px 20px !important;
    #dropdownMenuButtonAdmin {
      text-align: left;
      width: 100%;
    }
    #esker-navigation-admin {
      position: relative;
      background-color: transparent;
      left: initial !important;
      margin-bottom: 25px;
      position: initial !important;
      transform: none !important;

      .esker-navbar-dropdown-header {
        margin-bottom: 18px;
        margin-top: 18px !important;
      }

      a {
        margin-bottom: 10px;
        &:not(.esker-navbar-dropdown-header) + a {
          margin-top: 10px;
        }
      }
    }
  }

  #esker-navigation-dropdown-primary {
    padding: 10px 20px 10px 20px !important;
    #dropdownMenuButtonPrimary {
      text-align: left;
      width: 100%;
    }
    #esker-navigation-primary-menu {
      position: relative;
      background-color: transparent;
      width: 100%;
      position: initial !important;
      transform: none !important;

      #esker-navigation-primary-menu-links {
        border-top: 1px solid #2d414f;
        margin-top: 15px;
        padding-top: 15px;
      }

      .esker-navbar-dropdown-svg-container {
        border: 0;
        background-color: transparent;
        padding: 0;
        width: 50px;
        img {
          max-width: fit-content;
        }
      }

      .esker-navbar-dropdown-header {
        margin-top: 10px !important;
        margin-bottom: 10px;
      }

      .esker-primarynav-resources {
        background-color: #1d2b38;
        border-radius: 8px;
        margin-bottom: 20px;
        padding: 5px 15px 15px 15px;

        h6 {
          color: white !important;
          font-size: var(--navbar-font-size) !important;
        }
      }

      a {
        padding: 14px 0 14px 0 !important;
      }

      #learn-more-button {
        width: 100%;
      }
    }
  }
  /* Secondary navigation */
  div#navbarNav {
    display: block;
    padding-left: 32px;
    border: none;
    box-shadow: none;
    clear: both;

    ul {
      list-style: none;

      li {
        margin-left: 3px;
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
  /* Sandpit */
  #esker-navigation-dropdown-sandpit {
    padding: 10px 20px 10px 20px !important;
    #dropdownMenuButton {
      text-align: left;
      width: 100%;
    }
    #esker-navigation-sandpit {
      background-color: transparent;
      width: 100%;
      position: initial !important;
      padding: 15px 15px 15px 15px !important;

      a {
        padding: 5px 0 5px 0 !important;
      }
    }
  }
  #esker-navigation-leavesandpit {
    display: block;
    margin: 10px 20px 20px 20px !important;
    clear: both;
  }

  .btn-headbar span.caret {
    float: right;
    margin-top: 10px;
  }

  #esker-navigation-help {
    clear: both;
    padding: 10px 15px 7px 25px !important;
    svg {
      padding: 7px;
      width: 33px;
      .st0 {
        fill: var(--navbar-standout-color) !important;
      }
    }
    &::after {
      color: var(--navbar-text-color);
      content: attr(data-label);
      font-size: var(--navbar-font-size);
      font-weight: 600;
      position: relative;
      top: 1px;
    }
  }

  #esker-navigation-world {
    padding: 7px 18px 7px 25px !important;
    svg {
      padding: 7px;
      .st0 {
        stroke: white !important;
      }
      .st1 {
        fill: white !important;
      }
    }
    a#dropdownMenuLocale,
    button#dropdownMenuLocale {
      width: 100%;
      #dropdownMenuLocaleCaret {
        color: var(--navbar-text-color) !important;
        display: block;
      }
      &[aria-expanded="true"] {
        #dropdownMenuLocaleCaret {
          transform: rotate(180deg);
        }
      }
      &::after {
        color: var(--navbar-text-color);
        content: attr(data-label);
        font-size: var(--navbar-font-size);
        font-weight: 600;
        position: relative;
        top: 1px;
      }
    }

    #esker-navigation-locale {
      position: initial;
      background-color: transparent;
      padding: 10px 0px 7px 7px !important;
      width: 100%;
      position: initial !important;
      transform: none !important;

      .esker-navbar-circle-icon {
        border: 1px var(--navbar-text-color) solid;
        width: 19px;
        height: 19px;
        color: var(--navbar-text-color);
        font-size: 10px;
      }
    }
    .esker-navigation-scrolling a {
      clear: both;
      margin-bottom: 15px;
    }
  }

  #esker-navigation-dropdown-user {
    margin-top: 10px;
    #dropdownMenuButtonUser {
      display: none;
    }
    #esker-navigation-user {
      display: block;
      background-color: var(--navbar-background-color);
      padding: 10px 20px 10px 20px !important;
      position: relative;
      width: 100%;
      svg {
        fill: var(--navbar-text-color) !important;
      }
    }
    a {
      padding: 14px 0 14px 10px !important;
    }
  }

  // Hamburger toggle's animation
  .navbar-toggle {
    span {
      display: block;
      background-color: #4f4f4f;
      height: 3px;
      width: 25px;
      margin-top: 5px;
      margin-bottom: 5px;
      position: relative;
      left: 0;
      opacity: 1;
      transform-origin: center left;
      transition-property: transform, opacity;
      transition-duration: 0.35s;
      transition-timing-function: ease-out;
    }
    span:nth-child(1) {
      transform: translate(0%, 0%) rotate(0deg);
    }
    span:nth-child(2) {
      opacity: 1;
    }
    span:nth-child(3) {
      transform: translate(0%, 0%) rotate(0deg);
    }
    span:nth-child(1) {
      margin-top: 0.3em;
    }
    &:not(.collapsed) span:nth-child(1) {
      transform: translate(15%, -33%) rotate(45deg);
    }
    &:not(.collapsed) span:nth-child(2) {
      opacity: 0;
    }
    &:not(.collapsed) span:nth-child(3) {
      transform: translate(15%, 33%) rotate(-45deg);
    }
  }

  .esker-border {
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  .esker-navbar-p7,
  .esker-navbar-p10 {
    padding: 0 !important;
  }

  .esker-navbar-circle {
    border-color: var(--navbar-text-color) !important;
    font-family: var(--navbar-font-family);
  }

  .esker-divider {
    display: none;
  }

  .esker-navbar-panel-50 {
    width: 100% !important;
  }

  .esker-navbar-dropdown-ul li {
    margin-left: 35px;

    &::before {
      color: var(--navbar-text-color);
      left: -28px;
    }
  }

  .esker-mobile-button {
    display: block;
    margin: 10px 20px 20px 20px !important;
    clear: both;
  }

  .btn-headbar-full {
    background-color: var(--navbar-standout-color) !important;
    border-color: var(--navbar-standout-color) !important;
    color: var(--navbar-text-color) !important;
  }

  a {
    color: white !important;
    text-decoration: none;
    font-size: var(--navbar-font-size);
    vertical-align: middle;
  }

  svg {
    vertical-align: middle;
  }

  .bg-white {
    background-color: transparent !important;
  }

  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .dropdown-backdrop {
    display: none;
  }

  .text-white {
    color: white !important;
  }

  .border-right {
    border: none !important;
  }

  .center-content-vertical {
    display: flex;
    align-items: center;
  }

  .m-l10 {
    margin-left: 10px !important;
  }
  .m-r10 {
    margin-right: 10px !important;
  }
  .m-l5 {
    margin-left: 5px !important;
  }
  .m-r15 {
    margin-right: 15px !important;
  }
  .m-t15 {
    margin-top: 15px !important;
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid var(--navbar-text-color) !important;
    border-radius: 4px;
  }
  .navbar-toggle .icon-bar {
    background-color: var(--navbar-text-color) !important;
  }
  .navbar-nav {
    margin: 7.5px -15px;
  }

  .font-s15 {
    font-size: 15px !important;
  }
  .font-s18 {
    font-size: 18px !important;
  }

  .center-content-horizontal-vertical {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.esker-navbar-item a.esker-navbar-link-dropdown {
  white-space: initial;
}
