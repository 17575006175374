@use "@styles/cl/core/colors";

$flatpicker-primary: colors.$md-primary;
$flatpicker-border-focus: colors.$md-input-border-focus;
$flatpicker-other-month-day: #a7a9bc;
$flatpicker-current-month-day: #6b6c7e;

.flatpicker-primary-btn {
  background: $flatpicker-primary;
  color: white;
}
.flatpicker-outlined-primary-btn {
  background: transparent;
  color: $flatpicker-primary;
  border: 2px solid $flatpicker-border-focus;

  &:hover {
    @extend .flatpicker-primary-btn;
  }
}

.flatpicker-date-range {
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='11' height='11' viewBox='0 0 11 11' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M2.40625 0C2.49742 0 2.58485 0.0362164 2.64932 0.100682C2.71378 0.165148 2.75 0.252582 2.75 0.34375V0.6875H8.25V0.34375C8.25 0.252582 8.28622 0.165148 8.35068 0.100682C8.41515 0.0362164 8.50258 0 8.59375 0C8.68492 0 8.77235 0.0362164 8.83682 0.100682C8.90128 0.165148 8.9375 0.252582 8.9375 0.34375V0.6875H9.625C9.98967 0.6875 10.3394 0.832366 10.5973 1.09023C10.8551 1.34809 11 1.69783 11 2.0625V9.625C11 9.98967 10.8551 10.3394 10.5973 10.5973C10.3394 10.8551 9.98967 11 9.625 11H1.375C1.01033 11 0.660591 10.8551 0.402728 10.5973C0.144866 10.3394 0 9.98967 0 9.625V2.0625C0 1.69783 0.144866 1.34809 0.402728 1.09023C0.660591 0.832366 1.01033 0.6875 1.375 0.6875H2.0625V0.34375C2.0625 0.252582 2.09872 0.165148 2.16318 0.100682C2.22765 0.0362164 2.31508 0 2.40625 0ZM0.6875 2.75V9.625C0.6875 9.80734 0.759933 9.9822 0.888864 10.1111C1.0178 10.2401 1.19266 10.3125 1.375 10.3125H9.625C9.80734 10.3125 9.9822 10.2401 10.1111 10.1111C10.2401 9.9822 10.3125 9.80734 10.3125 9.625V2.75H0.6875Z' fill='%239D9D9D'/%3e%3c/svg%3e")
    no-repeat scroll 95% center;
  background-color: transparent !important;
  width: auto;
  padding-right: 1.5rem;
}

.flatpickr-calendar {
  width: 24rem;
  border-radius: 0.375rem;
  background: white;
  box-shadow: 0px 4px 8px 0px rgba(39, 40, 51, 0.12);

  .shortcut-buttons-flatpickr-wrapper,
  .flatpickr-innerContainer,
  .flatpickr-months,
  .flatpickr-weekdays {
    margin-bottom: 1rem;
  }

  .flatpickr-innerContainer {
    justify-content: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e7e7ed;
  }

  .flatpickr-months {
    padding: 0 1rem;

    .flatpickr-prev-month {
      display: none;
    }

    .flatpickr-next-month {
      display: none;
    }

    .flatpickr-current-month {
      left: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 1rem 0 1rem;

      .flatpickr-monthDropdown-months,
      .numInputWrapper > input {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        gap: 0.5rem;
        border-radius: 0.5625rem;
        color: var(--dark-dark, #272833);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.3125rem;
        padding-right: 1rem;
      }

      /* START: Year picker arrow styling */
      .numInputWrapper {
        span {
          opacity: 1;
          border: none;

          &.arrowUp:after,
          &.arrowDown:after {
            border-left: 5.5px solid transparent;
            border-right: 5.5px solid transparent;
            border-radius: 2px;
          }
          &.arrowUp:after {
            border-bottom: 5.5px solid #6b6c7d;
            bottom: 16%;
          }
          &.arrowDown:after {
            border-top: 5.5px solid #6b6c7d;
            top: 16%;
          }

          &:hover {
            background: none;
          }
        }
        &:hover {
          background: none;
        }
      }
      /* END: Year picker arrow styling */

      select.flatpickr-monthDropdown-months {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3e%3cmask id='mask0_297_3952' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='3' y='1' width='10' height='14'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.44911 1.19498L12.3143 5.06082C12.7284 5.47488 12.4354 6.18265 11.8505 6.18265H4.11805C3.53309 6.18265 3.24012 5.47488 3.65321 5.06082L7.51942 1.19498C7.77626 0.938263 8.19227 0.938263 8.44911 1.19498ZM7.54872 14.8052L3.68251 10.9392C3.26942 10.5253 3.56239 9.81754 4.14735 9.81754H11.8798C12.4647 9.81754 12.7577 10.5253 12.3436 10.9392L8.4784 14.8052C8.22157 15.0618 7.80555 15.0618 7.54872 14.8052Z' fill='%236B6C7E'/%3e%3c/mask%3e%3cg mask='url(%23mask0_297_3952)'%3e%3crect width='16' height='16' fill='%236B6C7E'/%3e%3c/g%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 50%;

        option {
          color: #6b6c7e;
          background-color: white;
        }
      }
    }
  }

  .dayContainer {
    gap: 0.25rem;
    justify-content: space-evenly;

    .flatpickr-day {
      display: flex;
      width: 1.5rem;
      border-radius: 50%;
      flex-direction: column;
      justify-content: center;
      color: $flatpicker-current-month-day;
      background-color: #f7f8f9;
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.3rem; /* 1.3125rem */

      &.inRange,
      &.startRange,
      &.endRange,
      &.selected {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        color: white !important;
        background-color: $flatpicker-primary;
        border-color: $flatpicker-border-focus;
        &:hover {
          border-color: $flatpicker-border-focus;
        }
      }

      &.prevMonthDay,
      &.nextMonthDay {
        color: $flatpicker-other-month-day;
      }
      &.today {
        color: $flatpicker-primary;
        border-color: $flatpicker-border-focus;
      }
      &:hover {
        border-color: $flatpicker-border-focus;
      }
    }
  }

  .flatpickr-weekdaycontainer {
    display: flex;
    justify-content: center;
    align-items: center;

    span.flatpickr-weekday {
      color: #272833;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      line-height: 1.3125rem;
      font-size: 0;
      width: 1.5rem;

      &::first-letter {
        font-size: 0.875rem !important;
      }
    }
  }

  .shortcut-buttons-flatpickr-wrapper {
    background-color: transparent !important;
    justify-content: end;
  }

  .shortcut-buttons-flatpickr-buttons {
    display: flex;
    justify-content: space-between;
    padding-right: 1rem;
    gap: 0.5rem;

    button {
      @extend .flatpicker-outlined-primary-btn;
      width: 5.375rem;
      height: 2.5625rem;
      border-radius: 0.563rem;
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.3125rem;
    }

    button[data-index="1"] {
      @extend .flatpicker-primary-btn;
    }
  }

  .flatpickr-select-wrapper {
    width: fit-content;
    margin: 0 1rem;
    position: absolute;
    padding-top: 0.5rem;
    z-index: 1;

    & select.filter-select-box {
      flex-grow: 1;
      margin-right: 2rem;
      display: flex;
      height: 2.6rem;
      width: auto;
      padding: 0.5rem 2rem 0.5rem 1rem;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 0.375rem;
      border: 1px solid #e7e7ed;
      background: #f7f8f9;
      outline: none;

      color: #6b6c7e;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;

      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-repeat: no-repeat;
      background-position-x: 90%;
      background-position-y: 50%;

      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='24' viewBox='0 0 18 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='4.5' y='0.5' width='13' height='23' rx='6.5' fill='white' stroke='%23CDCED9'/%3e%3cpath d='M11.2597 18.3843L14.3837 15.1928C14.739 14.8197 14.1998 14.2459 13.8414 14.6237L11.0002 17.5165L8.1605 14.6253C7.79743 14.2459 7.26132 14.8229 7.61667 15.1959L10.7391 18.3843C10.8951 18.5456 11.1161 18.5314 11.2597 18.3843Z' fill='%236B6C7E'/%3e%3cpath d='M10.7402 5.61566L7.61751 8.80424C7.26058 9.17574 7.79674 9.75275 8.1614 9.37492L10.9998 6.48355L13.8413 9.3765C14.1998 9.75433 14.7391 9.1789 14.3837 8.8074L11.2594 5.61566C11.1157 5.46864 10.8948 5.45441 10.7402 5.61566Z' fill='%236B6C7E'/%3e%3c/svg%3e ");
    }
  }
}
