.select {
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 22px;
  padding: 5px;
  position: relative;
  height: 30px;
  overflow: hidden;
  min-width: 46px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 8px -1px rgba(136, 136, 136, 0.5);
}

.input {
  height: 30px;
  margin-top: 0;
  font-size: 12px;
  border: 1px solid #e6e6e6;
  color: #aaa;
  box-shadow: 0px 0px 8px -1px rgba(136, 136, 136, 0.5);
}
