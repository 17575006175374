.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #d1d1d1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags__label {
  display: none;
}

.react-tags.is-active {
  box-shadow: 0 0 5px #75c0f7 !important;
  border: 1px solid #75c0f7 !important;
}

.react-tags__list,
.react-tags__list li {
  display: inline;
}

.react-tags__tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
}

.react-tags__tag:hover,
.react-tags__tag:focus {
  border-color: #b1b1b1;
}

.react-tags__combobox {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__combobox {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__combobox-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: none !important;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  width: 100% !important;
}

.react-tags__combobox-input::-ms-clear {
  display: none;
}

input.react-tags__combobox-input[type="text"]:focus {
  border: none !important;
  box-shadow: none !important;
}

.react-tags__listbox {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 30em) {
  .react-tags__listbox {
    width: auto;
  }
}

.react-tags__listbox-option {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__listbox-option mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__listbox-option:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__listbox-option.is-active {
  background: #b7cfe0;
}

.react-tags__listbox-option.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.fa-drag-handle:before {
  content: "\f142 \f142";
  letter-spacing: 0.1em;
}
