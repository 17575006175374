.toggle-switch-component {
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
  }

  .label-text {
    width: 68px;
  }

  .toggle-switch {
    position: relative;
    display: block;
    width: 45px;
    height: 25px;
    padding: 3px;
    cursor: pointer;
    background-color: #8f96ae;
    opacity: 20%;
    transition: all 0.3s ease;
    &::after {
      content: "";
      position: absolute;
      left: 3px;
      width: 19px;
      height: 19px;
      background-color: #ffffff;
      transition: left 0.3s ease;
    }
    &.pill {
      border-radius: 35px;
      &::after {
        border-radius: 50%;
      }
    }
  }

  input:checked + .toggle-switch {
    opacity: 1;
    background-color: $md-primary;
  }

  input:checked + .toggle-switch::after {
    left: 23px;
  }
}
