.button-tabs-container {
  background-color: #f2f4f6;
  border: none;
  border-radius: 4px;
  padding: 5px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  button {
    border: none;
    border-radius: 2px;
    height: 30px;
    color: #64646480;
    font-weight: 600;
    background-color: transparent;

    &.active {
      color: #646464;
      background-color: white;
    }
  }

  // This is to make sure that the space between the tabs is not too much
  // when we just have two tabs.
  &:has(li:nth-child(2):last-child) {
    justify-content: flex-start;
  }
}
