@use "@styles/cl/core/colors";

$badge-bgcolor: colors.$md-primary;
$white: colors.$md-white;
.multi-level-stack {
  width: 100%;
  height: 150px;
  display: flex;
}

.container {
  background-color: $white;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  .header {
    background: #fafbfc;
    border-bottom: 1px solid #eeeeee;
    height: 60px; // we can probably remove this hard coded height once we have the final design
    .badge {
      height: 30px;
      width: 30px;
      background-color: $badge-bgcolor;
      color: $white;
      text-align: center;
      padding: 5px 0;
    }
  }
  .toggle-button {
    border: none;
    background-color: transparent;
  }
}

.button {
  position: relative;
  border: none;
  border-radius: 2px;
  text-align: left;
  height: 30px;
  width: 100%;
  // TODO: Sort out this color, it needs to go in the colors file
  color: #64646480;
  font-weight: 600;
  background-color: transparent;

  &-active {
    // TODO: Sort out this color, it needs to go in the colors file
    color: #646464;
    background-color: #f2f4f6;
    &::after {
      position: absolute;
      content: "\203A";
      font-size: 20px;
      font-weight: 400;
      top: 0;
      right: 5px;
    }
  }
}

.slim-scroll {
  /* Slim scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #edf0f2 $white;
}

.slim-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.slim-scroll::-webkit-scrollbar-track {
  background: $white;
}

.slim-scroll::-webkit-scrollbar-thumb {
  background: #edf0f2;
  border-radius: 10px;
}
