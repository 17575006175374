// All of the colors used in the Market Dojo UI,s. This dose not include the
// default bootstrap colors and there variants. To view all the colors please
// see the below link.
// See: https://getbootstrap.com/docs/5.0/customize/color/#all-colors

// The base colors.
$md-white: #ffffff;
$md-black: #243746;

$md-primary: #0098aa;
$md-secondary: #253646;

// State colors
$md-success-fg: #24660f;
$md-success-bg: #bfe4d7;

$md-error-fg: #d64242;
$md-error-bg: #ffdada;

$md-info-fg: #d7b334;
$md-info-bg: #fff2da;

// Not to be confused with the old md blue
$md-esker-blue: #008aff;

// Input colours
// Figma - https://www.figma.com/design/KmoPJxUJ3K0kbVljrbYvvS/Sourcing-Dojo---Esker-Redesign?node-id=1864-2844&t=vF0w2mmbkPBK4mtB-0
$md-input-border: #97a3ae;
$md-input-border-hover: #52bac6;
$md-input-border-focus: $md-primary;
$md-input-border-error: $md-error-fg;
$md-input-border-disabled: #97a3ae;
$md-input-background: white;
$md-input-background-disabled: #eff0f2;
$md-input-text: $md-black;
$md-input-text-disabled: #8f96ae;

// The base color for the Market Dojo Blue theme. This can be use for legacy
// UIs when you need things in our classic blue colors.
$md-market-dojo-blue: #0d90ff;

$md-light-grey: #e7ecf180;
$md-grey: #fafafa;
$md-border-grey: #eff2f3;

// The color of all the title text.
$md-title-color: #415464;

// Colors for each of the Market Dojo modules
$md-sourcing: #f32735;
$md-category: #2496d3;
$md-contract: #ff6c0e;
$md-innovation: #3bbfad;
$md-quick-quotes: #0099a8;
$md-sim: #834192;
$md-srm: #fd4a5c;

// Tabs default
$nav-pills-link-active-color: #1a83c8;
$nav-pills-link-active-bg: white;
$nav-link-color: #8899ab;
$nav-link-hover-color: #1a83c8;

// Label default
$label-text-only-color: $md-black;
$label-emphasis-color: #4c5761;
$label-success-color: $md-success-fg;
$label-outline-color: $md-primary;

// Link default
$link-plain-color: #97a3ae;
$link-emphasis-color: $md-primary;

// Panel default
$panel-border-color: #dadfe3;
$panel-head-background-color: #fafbfc;

// Button default
$btn-emphasis-color: $md-primary;

// Modal default
$modal-background-color: #fafbfc;
$modal-header-background-color: white;
$modal-footer-border-color: #eff2f3;
$modal-border-color: #d8d8d8;

// Alert default
$alert-info-background-color: #f2fdfd;
$alert-success-background-color: #e0f5e9;
$alert-warning-background-color: #fdf3e5;
$alert-danger-background-color: #f9eae8;
$alert-info-border-color: #88c3ca;
$alert-success-border-color: #88aec9;
$alert-warning-border-color: #a9b177;
$alert-danger-border-color: #c98b89;

// Price Input
$price-input-border: #97a3ae;
$price-input-border-valid: #399c4f;
$price-input-border-invalid: $md-error-fg;
$price-input-currency-background: #e7ecf1;
$price-input-currency-valid-background: #e1f0e5;
$price-input-currency-invalid-background: #fbecec;
$price-input-text-color: $md-black;
