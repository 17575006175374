.enhanced-select {
  &__control {
    &,
    &:hover {
      border: $form-select-border-width solid $form-select-border-color;
    }
    @include border-radius($form-select-border-radius, 0);
    @include box-shadow($form-select-box-shadow);
    @include transition($form-select-transition);
    &--is-focused {
      &,
      &:hover {
        border-color: $form-select-focus-border-color;
      }
      outline: 0;
      @if $enable-shadows {
        @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: $form-select-focus-box-shadow;
      }
    }
    &--is-disabled {
      color: $form-select-disabled-color;
      background-color: $form-select-disabled-bg;
      border-color: $form-select-disabled-border-color;
    }
  }

  &__placeholder {
    color: $input-placeholder-color;
  }

  &__value-container {
    padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
  }

  &__indicator-separator {
    margin: $form-select-padding-y 0;
  }

  &__menu {
    margin: 0;
    border: $dropdown-border-width solid $dropdown-border-color;
    @include border-radius($dropdown-border-radius);
    box-shadow: none;
    @include box-shadow($dropdown-box-shadow);
    margin-top: $dropdown-spacer;
  }

  &__menu-list {
    padding: $dropdown-padding-y $dropdown-padding-x;
  }

  &__option {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    color: $dropdown-link-color;
    &--is-focused {
      color: $dropdown-link-hover-color;
      @include gradient-bg($dropdown-link-hover-bg);
    }
    &--is-selected {
      color: $dropdown-link-active-color;
      text-decoration: none;
      @include gradient-bg($dropdown-link-active-bg);
    }
    &--is-disabled {
      color: $dropdown-link-disabled-color;
    }
  }

  &__group-heading {
    padding: $dropdown-header-padding-y $dropdown-header-padding-x;
    @include font-size($font-size-sm);
    color: $dropdown-header-color;
    text-transform: none;
    font-weight: bold;
    margin: 0;
  }

  &--sm {
    .enhanced-select__value-container {
      padding-top: $form-select-padding-y-sm;
      padding-bottom: $form-select-padding-y-sm;
      padding-left: $form-select-padding-x-sm;
      @include font-size($form-select-font-size-sm);
      @include border-radius($form-select-border-radius-sm);
    }
  }
  &--lg {
    .enhanced-select__value-container {
      padding-top: $form-select-padding-y-lg;
      padding-bottom: $form-select-padding-y-lg;
      padding-left: $form-select-padding-x-lg;
      @include font-size($form-select-font-size-lg);
      @include border-radius($form-select-border-radius-lg);
    }
  }
}
