html {
  // Reset the font size on the HTML element for use with bootstrap5. Bootstrap
  // 3 sets it to 10px that makes it really hard to use rem units anywhere in
  // the application. As going forward we should alway be using relative units
  // 10px is impossible to work with
  font-size: 14px !important;
}

$font-family-sans-serif:
  "Open Sans",
  system-ui,
  -apple-system,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  "Noto Sans",
  "Liberation Sans",
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji" !default;

/**
 * Core bootstrap variables and mixins
 */
@import "bootstrap5/scss/functions";
@import "bootstrap5/scss/variables";
@import "bootstrap5/scss/variables-dark";
@import "bootstrap5/scss/mixins";
@import "bootstrap5/scss/maps";
@import "bootstrap5/scss/utilities";

/**
 * The root needs to be outside of the .md-bs5 class so its under the `:root`
 * pseudo selector
 */
@import "bootstrap5/scss/root";

.md-bs5 {
  font-family: $font-family-sans-serif;

  /**
   * Bootstrap reboot to reset the browser defaults
   */
  @import "bootstrap5/scss/reboot";

  /**
   * Market Dojo variables and mixins
   */
  @import "./core/colors";
  @import "./core/variables";
  @import "./core/utilities";

  /**
   * Bootstrap utilities api for spacing components
   */
  @import "bootstrap5/scss/helpers";
  @import "bootstrap5/scss/utilities/api";

  /**
   * Bootstrap modules we are using
   */
  @import "bootstrap5/scss/buttons";
  @import "bootstrap5/scss/forms";
  @import "bootstrap5/scss/spinners";
  @import "bootstrap5/scss/tables";
  @import "bootstrap5/scss/nav";
  @import "bootstrap5/scss/dropdown";
  @import "bootstrap5/scss/modal";
  @import "bootstrap5/scss/close";
  @import "bootstrap5/scss/alert";

  /**
   * Market Dojo components
   */
  @import "./button/index";
  @import "./input/index";
  @import "./input/date";
  @import "./table/index";
  @import "./tabs/index";
  @import "./panel/index";
  @import "./label/index";
  @import "./link/index";
  @import "./modal/index";
  @import "./select/enhanced";
  @import "./select/select2";
  @import "./alert/index";
  @import "./price_input/index";
  @import "./toggle/index";
  @import "./button_tabs/index";
  @import "./timeline/index";
  @import "./avatar/index";
}
