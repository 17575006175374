.scrolling_inner {
  border: 1px solid rgb(238, 238, 238);
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 0;
  height: 270px;
  width: 100%;
  -ms-overflow-x: hidden;
  -ms-overflow-y: auto;
}
.contract_scrolling_inner {
  border: 1px solid rgb(238, 238, 238);
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 0;
  height: 100%;
  max-height: 285px;
  width: 100%;
  -ms-overflow-x: hidden;
  -ms-overflow-y: auto;
}
.show_more_cf {
  color: rgb(255, 140, 0);
  border-color: rgb(255, 140, 0);
  background-color: transparent;
}
.show_more_cf:hover {
  background-color: #ebebeb;
}
.show_more_cf:focus {
  box-shadow: none;
}
.cf_full_height {
  max-height: 100%;
}

.message-bar .btn-group,
.message-bar button {
  margin-right: 5px;
}

.small-gray-box {
  margin-left: 5px;
}

.messages-row .message-item .ms-item-header .fa-circle {
  margin-left: 5px;
}

.message-content_wrapper .btn-group ul.dropdown-menu {
  top: 100%;
  margin-top: 6px;
  min-width: 180px;
}

.sim-dojo .messages-row .message-item .ms-item-body {
  padding: 0 20px 20px 20px;
  font-size: 13px;
}

.supplier-filter {
  float: left;
}

.active-supplier-filter .select2-container--default .select2-selection--single {
  border-color: #008aff;
}

.supplier-filter .select2-selection__placeholder {
  color: #545454 !important;
  font-size: 13px;
  text-align: center;
}

.supplier-filter .select2-selection__rendered {
  color: 545454;
  text-align: center;
}

.fa-2x {
  font-size: 15px !important;
}

.message_area {
  width: 100%;
  height: 130px;
}

.overflow-x-auto {
  overflow-x: auto;
  -ms-overflow-x: auto;
}

.width-fit {
  width: fit-content;
}
