$sizes: (
  small: 24px,
  medium: 48px,
  large: 96px,
);

.avatar {
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--avatar-background, grey);
  color: var(--avatar-color, white);
  @each $size, $value in $sizes {
    &.avatar-#{$size} {
      width: $value;
      height: $value;
      font-size: $value / 2.5;
    }
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .avatar-initial {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    height: 100%;
  }
}
