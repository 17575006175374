.price-input {
  display: flex;
  align-items: center;

  .currency-label {
    color: $price-input-text-color;
    background-color: $price-input-currency-background;
    padding: 0.6rem 1.1rem 0.6rem 1.1rem;
    border-radius: $price-input-border-radius;
    border: 0.1rem solid $price-input-border;

    &.prefix {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.suffix {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .price-field {
    flex: 1;
    color: $price-input-text-color;
    border: 0.1rem solid $price-input-border;
    border-radius: $price-input-border-radius;
    outline: none;
    padding: 0.6rem 0.6rem 0.6rem 1.1rem;

    &.prefix {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.suffix {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .error-icon {
    visibility: hidden;
    width: 22px;
    height: 22px;
    margin-left: 0.5rem;
  }

  &.valid {
    .currency-label {
      border-color: $price-input-border-valid;
      background-color: $price-input-currency-valid-background;
    }
    .price-field {
      border-color: $price-input-border-valid;
    }
  }

  &.invalid {
    .currency-label {
      border-color: $price-input-border-invalid;
      background-color: $price-input-currency-invalid-background;
    }
    .price-field {
      border-color: $price-input-border-invalid;
    }
    .error-icon {
      visibility: visible;
    }
  }
}
