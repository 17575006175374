.company-integrations-container {
  grid-template-columns: 15% 1fr;

  @media screen and (max-width: 1025px) {
    grid-template-columns: 30% 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .sign-on-methods-wrapper {
    background: $md-light-grey;
    border-radius: 0.25rem;
  }

  h4,
  h5,
  p,
  .form-group label,
  .action-left a {
    color: $label-text-only-color !important;
  }

  .company-integrations-grid-wrapper {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);

    @media screen and (max-width: 1025px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .integration-card {
      grid-template-rows: auto;
      border: 0.063rem solid $price-input-currency-background;
      border-radius: 0.25rem;
      padding-bottom: 1rem !important;

      .integration-logo {
        background: $price-input-currency-background;
        border-radius: 0.25rem;
      }

      .integration-header {
        grid-template-columns: 2fr 1fr;

        .integration-name {
          color: $md-primary;
        }
      }

      .integration-tag,
      .integration-footer .integration-divider {
        border-radius: 3.125rem;
        background: $price-input-currency-background;
      }

      .integration-footer .integration-divider {
        width: calc(100% + 3rem);
        height: 0.0625rem;
        margin-left: -1.5rem;
      }

      .integration-footer-actions {
        grid-template-columns: repeat(2, 1fr);
      }

      .action-left img {
        margin-bottom: 0.1rem;
      }
    }
  }
}

[data-integration-filter-target="filterButton"].active {
  color: #fff !important;
}
