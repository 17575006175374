.nav-link {
  cursor: pointer;
  font-weight: bold;

  // active state
  &.active {
    border-bottom: 0.15rem solid $nav-pills-link-active-color;
  }
}

// Due to clashed with pre-existing CSS, we need to override this
button.dropdown-toggle:focus {
  box-shadow: none !important;
  border: none !important;
}

.open > button.dropdown-toggle::after {
  transform: rotate(180deg);
}

// Due to clashed with pre-existing CSS/JS, the default Bootstrap 5 dropdown
// menu is not working properly. We need the following to make it work
.open > .dropdown-menu {
  display: block;
  left: 1.5rem;
  padding: 10px;
}

.dropdown-item {
  padding: 10px;
  width: auto;
}
