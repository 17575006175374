label {
  font-size: 1rem;
}

input.form-control[type="search"] {
  padding-left: $input-height-inner;
  background-image: url(@images/icons/search.svg);
  background-repeat: no-repeat;
  background-position: left $input-height-inner-quarter center;
  background-size: $input-height-inner-half $input-height-inner-half;

  // Keep the input height the same as the buttons so they line up
  padding-top: 1.55rem !important;
  padding-bottom: 1.55rem !important;

  &::placeholder {
    color: #989da3;
  }
}

input.form-control[type="text"][data-type="code"] {
  padding-left: $input-height-inner;
  background-image: url(@images/icons/book.svg);
  background-repeat: no-repeat;
  background-position: left $input-height-inner-quarter center;
  background-size: $input-height-inner-half $input-height-inner-half;
  border-color: var(--bs-border-color) !important;

  // Keep the input height the same as the buttons so they line up
  padding-top: 1.55rem !important;
  padding-bottom: 1.55rem !important;

  &::placeholder {
    color: #989da3;
  }

  &:focus {
    border-color: rgb(134, 182.5, 254) !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  }
}

.form-check-input {
  &[type="radio"] {
    // We need to use and round amount of pixels here, so we can make a perfect
    // circle. When using rem's it can turn out to be `x.5px` in this case the
    // radio button looks a bit oval.
    width: 18px;
    height: 18px;

    &.is-market-dojo {
      background-color: white;
      border-color: $md-market-dojo-blue;
      transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      &:hover {
        background-color: mix(black, white, 15%);
      }
      &:active {
        background-color: mix(black, white, 25%);
      }
      &:checked {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='rgb(#{to-rgb($md-market-dojo-blue)})'/></svg>");
      }
      &:focus-visible {
        border-width: 4px;
        box-shadow: 0 0 0 0.25rem rgba($md-market-dojo-blue, 0.5);
      }
    }
  }
  &[type="checkbox"] {
    &:hover {
      background-color: mix(black, white, 10%);
    }
    &:active {
      background-color: mix(black, white, 20%);
    }
    &:focus-visible {
      box-shadow: 0 0 0 0.25rem rgba($md-primary, 0.25);
    }
    &:checked {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='rgb(#{to-rgb($md-primary)})' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
    }
    &.is-market-dojo {
      $md-checkbox-color: #343439;
      $md-checkbox-background: #edf0f2;
      border: 2px solid $md-checkbox-background;
      background-color: white;
      transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      &:hover {
        background-color: mix($md-checkbox-background, white, 50%);
      }
      &:active {
        background-color: mix($md-checkbox-background, white, 75%);
      }
      &:checked {
        background-color: $md-checkbox-background;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='rgb(#{to-rgb($md-checkbox-color)})' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
        &:hover {
          background-color: mix(black, $md-checkbox-background, 20%);
        }
        &:active {
          background-color: mix(black, $md-checkbox-background, 35%);
        }
      }
      &:focus-visible {
        border-color: $md-market-dojo-blue;
        box-shadow: 0 0 0 0.25rem rgba($md-market-dojo-blue, 0.5);
      }
    }
  }
}
