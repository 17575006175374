span.md-label {
  font-weight: bold;
  border: 0.1rem solid;
  border-radius: 0.2rem;
  padding: 0.145rem 0.43rem;
  white-space: nowrap;

  &.label-small {
    font-size: 0.65rem;
    padding: 0.135rem 0.4rem;
  }

  &.label-medium {
    font-size: 1rem;
    padding: 0.145rem 0.43rem;
  }

  &.label-large {
    font-size: 1.25rem;
    padding: 0.2rem 0.5rem;
  }

  &.label-text-only {
    color: $label-text-only-color;
    background-color: transparent;
    border-color: transparent;
  }

  &.label-emphasis {
    color: white;
    background-color: $label-emphasis-color;
    border-color: $label-emphasis-color;
  }

  &.label-success {
    color: white;
    background-color: $label-success-color;
    border-color: $label-success-color;
  }

  &.label-outline {
    color: $label-outline-color;
    background-color: transparent;
    border-color: $label-outline-color;
  }
}
