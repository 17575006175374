.submitBtn {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  :global(.fa-check) {
    color: green;
  }
}
