.table-wrapper {
  padding: 0;
  border: 1px solid #dadfe3;
  border-radius: 0.42857rem;

  // A table inside a wrapper should never have margin. This will affect the
  // rounded border on the wrapper.
  .table {
    margin-bottom: 0;
  }
}

.table {
  border-collapse: collapse;

  // If a table has no border, we should remove the border from all its
  // children too.
  &.border-none > :not(caption) > * > * {
    border: none;
  }

  > :not(caption) > * > * {
    background-color: inherit;
  }

  &.is-compact {
    thead {
      th {
        padding: 0.75rem;
      }
    }

    tbody {
      td {
        padding: 0.75rem;
      }
    }
  }

  thead {
    th {
      padding: 1.25rem;
      font-weight: 600;
      color: #989da3;
      text-align: left;

      // Display the text as it comes. This is to override the transform from
      // the original tables that mess with the text.
      text-transform: none !important;
    }
  }

  tbody {
    tr {
      background-color: transparent;
    }

    td {
      padding: 1.25rem;
      border-top: 2px solid transparent;
      vertical-align: top;
      font-size: 1rem;

      &.is-align-middle {
        vertical-align: middle;
      }
    }
  }

  &.is-striped {
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f8f9fa !important;
        }
      }
    }
  }
}
