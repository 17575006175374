@use "sass:color";
@use "@styles/cl/core/colors";

.tag {
  position: absolute;
  right: 0;
  top: 0;

  font-size: 0.75rem;

  // Need to fit in with whats there
  padding: 3px 3px;
  border-radius: 0 0 0 3px;
  line-height: 100%;
}

.host-tag {
  @extend .tag;

  background: colors.$md-esker-blue;
  color: colors.$md-white;
}

.participant-tag {
  @extend .tag;

  color: colors.$md-esker-blue;
  background-color: color.scale(colors.$md-esker-blue, $lightness: 75%);
}
