.dj-vgrid {
  width: 100%;
  overflow: auto;

  // Reset for all tables having a margin defined in the global css
  table.table {
    margin-bottom: 0 !important;
  }

  table {
    width: 100%;
  }

  tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  td {
    flex-grow: 1;
    width: 100%;
    padding: 0.5rem 0.75rem;
  }

  .dj-vgrid-sticky-left {
    z-index: 2;
    position: sticky;
    left: 0;
  }
}
