.alert {
  // Resetting to defaults to avoid conflict with the preexisting styles (e.g. .alert-info in _unifyui.scss)
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding-left: 14px !important;
  background-image: none !important;

  background-repeat: no-repeat;
  background-size: 20.11px;
  background-position: 12px 50%;
  box-sizing: border-box;
  border-radius: 7px;
  --bs-alert-border-color: transparent;

  .alert-container {
    display: flex;
    align-items: center;
    .alert-content {
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      color: #243746;
      padding-left: 15px;
    }
  }

  .alert-close {
    padding: 0;
    background: 0 0;
    border: 0;
    -moz-appearance: none;
    appearance: none;
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity = 20);
    opacity: 0.2;
  }
}

@mixin alert-filter($invert, $sepia, $saturate, $hueRotate, $brightness, $contrast) {
  filter: invert($invert) sepia($sepia) saturate($saturate) hue-rotate($hueRotate) brightness($brightness)
    contrast($contrast);
}

@mixin alert-style($background-color, $border-color, $img-filter) {
  background-color: $background-color;
  border-bottom: 2px solid $border-color;

  img {
    width: 20px;
    height: 20px;
    @include alert-filter($img-filter...);
  }
}

.alert-info {
  @include alert-style($alert-info-background-color, $alert-info-border-color, 75% 46% 235% 138deg 91% 86%);
}

.alert-success {
  @include alert-style($alert-success-background-color, $alert-success-border-color, 69% 6% 1571% 162deg 97% 88%);
}

.alert-warning {
  @include alert-style($alert-warning-background-color, $alert-warning-border-color, 73% 24% 467% 28deg 90% 91%);
}

.alert-danger {
  @include alert-style($alert-danger-background-color, $alert-danger-border-color, 80% 37% 688% 306deg 82% 90%);
}
